import React from 'react'
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby'
import Subscribe from '../components/subscribe'

const LettersIndex = ({
	data: {
		allMarkdownRemark: { edges },
	},
}) => {
	const letters = edges
		.filter(edge => edge.node.fields.isEmail)
		.filter(edge => !edge.node.frontmatter.draft)
		.map(edge => (
			<div key={edge.node.id} className="post-listing">
				<Link to={edge.node.fields.slug}>{edge.node.frontmatter.title}</Link>
				<small>{edge.node.frontmatter.date}</small>
				<span>{edge.node.excerpt}</span>
			</div>
		))

	return (
		<Layout title="Newsletter">
			<div className="article">
				<section>
					<p>
						I send a weekly newsletter on software development, remote work, and freelancing. You can join it by
						entering your email below. No spam.
					</p>
				</section>
				<Subscribe />
				<h3>
					<center>Past Issues</center>
				</h3>
				<div>{letters}</div>
				<Subscribe />
			</div>
		</Layout>
	)
}

export default LettersIndex

export const pageQuery = graphql`
	query {
		allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
			edges {
				node {
					id
					excerpt(pruneLength: 250)
					frontmatter {
						title
						draft
						date(formatString: "MMMM DD, YYYY")
					}
					fields {
						slug
						isEmail
					}
				}
			}
		}
	}
`
